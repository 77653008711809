// Nepali
export default{
    form: {
        startSurveyButton: "सुरु गरौं",
        nextButton: "अर्को",
        submitAnswerButton: "उत्तर पठाउनुहोस्",
        continueButton: "जारी राख्नुहोस्",
        finishButton: "समाप्त गर्नुहोस्",
        headerPercentageCompletion: 'पूरा भयो',
        headerQuestionsCompleted: 'प्रश्नहरू',
        headerDefaultErrorMessage: 'ओहो! केहि गलत भयो।',
        brandingPoweredBy: 'द्वारा सञ्चालित',
        brandingPromoWhySurvey: 'किन सर्वेक्षण गर्नु हुन्छ जब तपाईं <b>{product}</b> प्रयोग गर्न सक्नुहुन्छ?',
        brandingPromoGetStarted: 'आजै सुरु गर्नुहोस्!',
        submissionPending: "तपाईंको voiceform पठाउँदैछ। कृपया आफ्नो ब्राउजरको विन्डो बन्द नगर्नुहोस्।",
        submissionCanCloseWindow: "तपाईंको voiceform सफलतापूर्वक पठाइएको छ। तपाईं अब विन्डो बन्द गर्न सक्नुहुन्छ 👍",
        mediaAttachmentOverlayCloseButton: "बन्द गर्नुहोस्",
        expiredFormHeader: "आउनु भएकोमा धन्यवाद!",
        expiredFormMessage: "यस सर्वेक्षणको समय सकिएको जस्तो देखिन्छ। कृपया voiceform को निर्माता लाई सम्पर्क गर्नुहोस्।",
        notPublishedFormHeader: "Voiceform प्रकाशित भएको छैन!",
        notPublishedFormMessage: "यस सर्वेक्षणलाई प्रकाशित गरिएको छैन जस्तो देखिन्छ। कृपया voiceform को निर्माता लाई सम्पर्क गर्नुहोस्।",
        notPublishedFormRetryButton: "फेरि प्रयास गर्नुहोस्",
        errorFormHeader: "ओहो!",
        errorFormMessage: "केहि गलत भएको हुनसक्छ। कृपया पछि फेरि प्रयास गर्नुहोस्।",
        errorFormRetryButton: "फेरि प्रयास गर्नुहोस्",
        emptyFormHeader: "आउनु भएकोमा धन्यवाद!",
        emptyFormMessage: "ओहो! यो फारम हाल खाली देखिन्छ, भर्नका लागि कुनै प्रश्न वा क्षेत्रहरू छैनन्। यस असुविधाको लागि हामी माफी चाहन्छौं। कृपया यो समस्याको बारेमा फारमका निर्मातालाई जानकारी दिनुहोस्। तपाईंको बुझाई र सहकार्यको लागि धन्यवाद!",

        restoreFormHeader: "तपाईंको एक फारम पूरा हुन बाँकी छ जस्तो देखिन्छ!",
        restoreFormMessage: "तपाईं पहिले जहाँबाट रोक्नुभएको थियो त्यहाँबाट जारी गर्न चाहनुहुन्छ?",
        restoreFormButton: "फारम जारी राख्नुहोस्",
        restoreFormButtonNew: "फेरि सुरु गर्नुहोस्",

        audioPermissionHeader: "के तपाईं आवाजले उत्तर दिन चाहनुहुन्छ?",
        audioPermissionMessage: "बोल्दा छिटो र सजिलो हुन्छ।",
        audioPermissionAcceptButton: "हो, म चाहन्छु",
        audioPermissionDenyButton: "छैन, धन्यवाद",
        audioPermissionErrorHeader: "माइक्रोफोन उपलब्ध छैन",
        audioPermissionErrorMessage: "माइक्रोफोन पहुँचको लागि कृपया ब्राउजरको अनुमति जाँच गर्नुहोस्।",
        audioPermissionErrorButton: "ठिक छ",
        audioRecorderFailedUpload: "रेकर्डिंग अपलोड गर्न असफल",
        silenceDetectorError: "हामी तपाईंलाई सुन्न सक्दैनौं। कृपया आफ्नो माइक्रोफोन प्राथमिकताहरू जाँच गर्नुहोस्।",
        audioRecorderButtonRecord: "रेकर्ड गर्नुहोस्",
        audioRecorderButtonRecordMore: "अझै रेकर्ड गर्नुहोस्",
        voiceResponseSelectionMessage: "तपाईं कसरी उत्तर दिन चाहनुहुन्छ चयन गर्नुहोस्...",
        voiceResponseSelectionOrDivider: "वा",
        voiceResponseTextInputPlaceholder: "उत्तर लेख्नुहोस्",
        voiceResponseTextInputCharacterCounterMinMessage: "कृपया कम्तीमा {min} अक्षरहरू लेख्नुहोस्",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} अक्षरहरू (कम्तीमा {min} अक्षरहरू)",
        voiceResponseTextInputCharacterValidationMessage: "{count} अक्षरहरू (कम्तीमा {min} अक्षरहरू)",

        // checkbox
        checkboxValidationTooFew: "तपाईंले कम्तीमा {min} विकल्प(हरू) चयन गर्नुपर्छ",
        checkboxValidationTooMany: "तपाईंले अधिकतम {max} विकल्प(हरू) चयन गर्न सक्नुहुन्न",
        checkboxNoneOfTheAboveOption: "माथिका कुनै पनि होइन",
        checkboxInvalidAnswer: "अवैध उत्तर।",

        // datepicker
        datePickerPlaceholder: "मिति चयन गर्नुहोस्",

        // dropdown
        // email
        emailLabel: "इमेल",
        emailInvalid: "अवैध इमेल।",
        // file-upload
        fileUploadPluginNameCamera: "क्यामेरा",
        fileUploadPluginNameCameraVideo: "भिडियो रेकर्ड गर्नुहोस्",
        fileUploadPluginScreenCast: "स्क्रीन रेकर्ड गर्नुहोस्",
        fileUploadVideoImportFilesDefault: 'रेकर्ड वा भिडियो अपलोड गर्नुहोस्:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'भिडियो रेकर्डिङको विकल्प चयन गर्नुहोस्:',
        fileUploadVideoImportFilesNoCamera: 'स्क्रीन भिडियो रेकर्ड गर्न बटन थिच्नुहोस्',
        fileUploadVideoImportFilesNoScreenCast: 'भिडियो रेकर्ड गर्न बटन थिच्नुहोस्',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'भिडियो रेकर्ड गर्न कुनै विकल्प उपलब्ध छैन',

        // matrix
        matrixValidationMessage: "उत्तर मान्य छ।",
        matrixRow: "पंक्ति",
        matrixColumn: "स्तम्भ",
        matrixRowRequired: "पंक्ति {rowTitle} आवश्यक छ।",
        matrixRadioGroup: "रेडियो समूह",
        matrixCheckboxGroup: "चेकबक्स समूह",
        matrixGroupRequired: "पंक्ति {rowTitle} का लागि। {type} {groupTitle} आवश्यक छ।",
        matrixColumnRequired: "पंक्ति {rowTitle} का लागि। स्तम्भ {columnTitle} आवश्यक छ।",
        matrixColumnInvalid: "पंक्ति {rowTitle} का लागि। स्तम्भ {columnTitle} अवैध छ।",
        matrixRequired: "आवश्यक छ।",
        matrixNumericMustBeNumber: "संख्या हुनुपर्छ।",
        matrixNumericMustBeGreaterThenMin: "{min} भन्दा ठूलो हुनुपर्छ।",
        matrixNumericMustBeLessThenMax: "{max} भन्दा सानो हुनुपर्छ।",
        matrixNumericMustBeInteger: "पूर्णांक हुनुपर्छ।",
        matrixNumericInvalidNumber: "अवैध संख्या।",

        // name
        nameLabel: "नाम",
        nameInvalid: "कृपया नाम र थर लेख्नुहोस्।",

        // nps
        npsNotLikely: "एकदम असम्भव",
        npsExtremelyLikely: "अत्यधिक सम्भावित",

        // numeric input
        numericInputRequired: "आवश्यक छ।",
        numericInputMustBeNumber: "संख्या हुनुपर्छ।",
        numericInputMustBeGreaterThenMin: "{min} भन्दा ठूलो हुनुपर्छ।",
        numericInputMustBeLessThenMax: "{max} भन्दा सानो हुनुपर्छ।",
        numericInputMustBeInteger: "पूर्णांक हुनुपर्छ।",
        numericInputInvalidNumber: "अवैध संख्या।",
        numericInputPlaceholder: "संख्या लेख्नुहोस्",

        // phone
        phoneInvalid: "फोन नम्बर अवैध छ।",
        phoneCountrySelectorLabel: 'देशको कोड',
        phoneCountrySelectorError: 'देश चयन गर्नुहोस्',
        phoneNumberLabel: 'फोन नम्बर',
        phoneExample: 'उदाहरण:',

        // boolean
        booleanYesLabel: "हो",
        booleanNoLabel: "होइन",

        //questionStep
        questionStepAudioQuestionLabel: "अडियो प्रश्न",

        // errors
        invalidPhoneNumber: "{phone} अवैध फोन नम्बर हो।",
        invalidEmail: "{email} अवैध इमेल ठेगाना हो।",
        questionIsRequired: "प्रश्न आवश्यक छ।",
        answerIsNotValid: "उत्तर मान्य छैन।",
        unfinishedProbingDialogError: "कृपया संवाद समाप्त गर्नुहोस्।",
        cannotResumePartialResponse: "आंशिक उत्तर पुनः सुरु गर्न सकिँदैन।",
        failedToSubmitForm: "फारम पठाउन असफल। कृपया आफ्नो इन्टरनेट जाँच गर्नुहोस् र फेरि प्रयास गर्नुहोस्।",

        //language picker
        searchLanguage: "भाषा खोज्नुहोस्",
    }
}
